import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { useStaticQuery, graphql } from 'gatsby'
import useScrollPercentage from 'react-scroll-percentage-hook'
import { rgba } from 'polished'
import Section from 'src/components/Section'
import Image from 'src/components/Image'
import Video from 'src/components/Video'
import Grid from 'src/components/Grid'
import Caption from 'src/components/Caption'
import { colors, mediaQueries as mq, util, typography } from 'src/styles'
import ScrollEntrance from 'src/components/ScrollEntrance'
import Column from 'src/components/Column'
import FiftyFifty from 'src/components/FiftyFifty'
import ContentfulRichText from 'src/components/ContentfulRichText'

import Logo from 'src/components/Logo'
// import IdeasLabLogo from 'src/assets/images/IdeasLabLogo-w.svg'
import SaveTheTidalBasinLogo from 'src/assets/images/footer/SaveTheTidalBasinLogo.svg'

const setPageTypeStyles = (pageType, orderIndex) => `
  ${pageType === 'home' ? `

  `: pageType === 'about' ? `

  `: pageType === 'proposals' ?`
    ${ util.responsiveStyles('padding-top', 175, 175, 175, 52) }
    ${
      orderIndex === 4 ? `${ util.responsiveStyles('padding-bottom', 175, 175, 175, 26) }` : 
      ``
    }
  `: pageType === 'proposal' ? `
    ${
      orderIndex === 4 ? `${ util.responsiveStyles('padding-top', 0, 0, 0, 26) }` : 

      ``
    }
  ` :``}
  
`

const WideMediaSection = styled(Section)`
  ${ ({ isFirstSection }) => !isFirstSection ? `
    ${ util.responsiveStyles('padding-top', 175, 175, 175, 52) }
  ` :  `` }

  ${ ({pageType, orderIndex}) => setPageTypeStyles(pageType, orderIndex)}

`

const WideMediaWrap = styled.div`
	${ ({ setHeight }) => setHeight !== 'auto' ? `
		position: relative;
		z-index: 2;
		height: ${ setHeight };
	` : `` }
`

const MainLogoWrap = styled.div`
	text-align: center;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-grow: 2;
 ${util.responsiveStyles('margin-top', 105 , 95 , 75 , 75 )}

`

const MainHeaderWrap = styled.div`
	padding-top: 90px;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const MainTextWrap = styled.div`
	padding-top: 50px;
	flex-grow: 0;
	flex-shrink: 0;
`

const SponsorLogos = styled(Grid)`
	flex-grow: 0;
`

const SponsorLogoColumn = styled.div`
	img {
		flex-grow: 1;
		&:not(.nps) {
			max-width: 140px;
			${ mq.mediumAndBelow } {
				max-width: 100px;
			}
		}
		&.nps {
			max-width: 54px;
			${ mq.mediumAndBelow } {
				max-width: 32px;
			}
		}
		// &.amex {
		// 	${ mq.mediumAndBelow } {
		// 		margin: 0 auto;
		// 	}
		// }
	}
	${ mq.mediumAndBelow } {
		text-align: center;

		p {
			margin-top: 1.5em;
			margin-bottom: 0;
		}
	}
`

const SponsorLogoRow = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	
`

const OverlayGrid = styled(Grid)`
	
`

const OverlayTop = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 20px;
`

const MediaImage = styled(Image)`
	${ ({ setHeight }) => setHeight !== 'auto' ? `
		> div {
			height: ${ setHeight };
			position: relative;
		}
		img {
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			width: 100%;
			height: 100%;
		}
	` : `` }
`

const CaptionOverlay = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	bottom: 1em;
	z-index: 3;
	${ mq.mediumAndBelow } {
		position: relative;
		bottom: auto;
		top: 100%;
		z-index: 1;
		background: ${ colors.black };
		padding-top: 8px;
	}
`

const CaptionBlock = styled.div`
`

const OverlaySection = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: ${ ({ verticalPlacement }) => {
    if (!verticalPlacement) return 'center'
    if (verticalPlacement === 'top') return 'flex-start'
    if (verticalPlacement === 'bottom') return 'flex-end'
    else return verticalPlacement
  } };
  text-align: ${ ({ overlayTextAlignment }) => overlayTextAlignment || 'left' };

  background: ${ ({ pageType }) => {
    if (pageType === 'home' || pageType === 'proposals' || pageType === 'history' || pageType === 'proposal' || pageType === 'challenges' ) return 'linear-gradient(180deg, rgba(0, 66, 104, 0) 50%, #004268 85%)'
  } };

  ${ ({ pageType }) => pageType === 'proposals' || pageType === 'history' || pageType === 'proposal' || pageType === 'challenges' ? `

    width: 100%
  ` :  `` }
  ${ ({ pageType }) => pageType === 'home'  ? `
    padding-bottom: 10px;
  ` :  `` }



`

const Text = styled.div`
	p {
		${ ({ textSize }) => typography[textSize] }
		margin-bottom: 0;
		&:first-of-type {
			margin-top: 0;
		}
		${ ({ alignment }) => alignment === 'center' && `
			margin-left: auto;
			margin-right: auto;
		` }
		${ ({ alignment }) => alignment === 'right' && `
			margin-left: auto;
		` }
	}
`

const StyledMainLogo = styled.div`
	${ ({ scrolled, percentage }) => scrolled === true  ? `
		svg {
			transform: scale(${(1 - percentage / 10) <= 1 ? 1 - percentage / 10 : 1});
		}
		${percentage/10 > 0.8 ? `
			opacity: 0;
		` : ``}
		transition: opacity 0.75s ease;
  ` :  `` }
`


const HomeATF = ({
  image,
  width,
  theme,
  prevTheme,
  nextTheme,
  caption,
  height,
  overlayHeader,
  overlayText,
  isFirstSection,
  index,
  pageType

}) => {
  if (!image) {
    return false
  }

  const fullWidth = width === 'fullWidth'


  const heightValues = {
    auto: 'auto',
    fullHeight: '100vh',
    mediumHeight: '70vh',
    shortHeight: '50vh'
  }

  const rootMargin = util.responsiveStyles('rootMargin', 140, 130, 110, 75);

  const { amex, nps, nthp, tnm } = useStaticQuery(
		graphql`
			query {
				amex: file(relativePath:{eq: "images/footer/AMEX.png"}) {
					publicURL
				}
				nps: file(relativePath: { eq: "images/footer/NPS.png" }) {
					publicURL
				}
				nthp: file(relativePath: { eq: "images/footer/NHTP_no tag.png" }) {
					publicURL
				}
				tnm: file(relativePath: { eq: "images/footer/TNM.png" }) {
					publicURL
				}
			}
		`)

  const host = process.env.HOST || process.env.GATSBY_HOST

	const { percentage } = useScrollPercentage({ windowScroll: true, threshold: 0 });

  return (
    <WideMediaSection
      setTheme={theme}
      nextTheme={nextTheme}
      prevTheme={prevTheme}
      padded={!fullWidth}
      isFirstSection={isFirstSection}
      pageType={pageType}
      orderIndex={index}
    >
      <WideMediaWrap setHeight={heightValues[height]}>
        <Grid small={fullWidth ? '[1]' : '1 [12] 1'}>
					
          <div>
              <MediaImage
                image={image.image}
                small={image.small}
                medium={image.medium}
                alt={image.description || image.title}
                setHeight={heightValues[height]}
                loading={isFirstSection ? 'eager' : 'lazy'}
              />
            
              <OverlaySection 
              	padded={!fullWidth} 
              	pageType={pageType} 
							>
            		<OverlayTop>
              		<MainLogoWrap>
										<StyledMainLogo
											// ref={ref}
											className="main-logo"
											percentage={percentage?.vertical.toPrecision(3)}
											scrolled={percentage?.vertical > 0 ? true : false }
										>
											<Logo />
	              		</StyledMainLogo>
              		</MainLogoWrap>

              		<SponsorLogos 
	                	small={'1 [12] 1'} 
	                	medium={'1 [4] 3 [5] 1'}
	                	large={'1 [4] 3 [5] 1'}
	              	>
	              		<SponsorLogoColumn>
	              			<p>Presented by</p>
											<img src={host + amex.publicURL} alt="Logo" className="amex" />
              			</SponsorLogoColumn>

              			<SponsorLogoColumn>
	              			<p>Partners</p>
	              			<SponsorLogoRow>
												<img src={host + nthp.publicURL} alt="Logo" className="nthp" />
												<img src={host + tnm.publicURL} alt="Logo" className="tnm" />
		              			<img src={host + nps.publicURL} alt="Logo" className="nps" />
											</SponsorLogoRow>
              			</SponsorLogoColumn>

	              	</SponsorLogos>

              	</OverlayTop>

              	<MainHeaderWrap>
	              	<Grid
	              		small={'1 [12] 1'} 
	                	medium={'1 [12] 1'}
	                	large={'1 [12] 1'}
	              	>
              			<Text textSize={'bodyMedium'} alignment={'center'}><ContentfulRichText richText={overlayHeader.json}/></Text>
              		</Grid>
                </MainHeaderWrap>

                <MainTextWrap>
	                <Grid
	                	small={fullWidth ? '1 [12] 1' : '2 [10] 2'} 
	                	medium={'7 [5] 1'}
	                	large={'7 [5] 1'}
	                >
	              		<Text textSize={'bodyMedium'} alignment={'center'}><ContentfulRichText richText={overlayText.json}/></Text>
	                </Grid>
                </MainTextWrap>

              </OverlaySection>
            
            
          </div>
        </Grid>
      </WideMediaWrap>
      {caption && (
        <Grid small="1 [12] 1">
          <div>
            <CaptionBlock>
              <Caption>{caption}</Caption>
            </CaptionBlock>
          </div>
        </Grid>
      )}
    </WideMediaSection>
  )
}

HomeATF.defaultProps = {
  width: 'margins',
  height: 'auto'

}

export default HomeATF
